import React from 'react';
import image from '../assets/images/logo-fond.svg';

const Bar_Equipe = () => {
    return (
        <section id="bar" className="bar-carte pt-200 position-relative pb-100">
            <div className="position-absolute end-25 top-15">
                <img className="image-fond" src={image}/>
            </div>
            <div className="container ">
                <div className="row">
                    <div className="col-lg-5 z-1">
                        <h2>BAR & CARTE</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5 z-1">
                        <p>NOTRE BAR EST LE CŒUR BATTANT DE L'ANTRE, ANIMÉ PAR NOTRE BARMAN. PASSIONNÉ PAR LA MUSIQUE TECHNO ET L'ART DE LA MIXOLOGIE, IL CRÉE UNE ATMOSPHÈRE ACCUEILLANTE ET ÉNERGIQUE, IDÉALE POUR PROFITER DE TES SOIRÉES "BEFORE" ET "AFTER".</p>
                        <p>LES FRAIS D'ENTRÉE (PAF) SONT DE 5 € POUR LES "BEFORE" ET DE 10 € POUR LES "AFTER", TE GARANTISSANT UNE EXPÉRIENCE INÉGALÉE EN COMPAGNIE DES MEILLEURS DJS DE LA SCÈNE.</p>
                        <p>QUE TU VIENNES POUR TE DÉTENDRE AVANT UNE NUIT ENDIABLÉE OU POUR PROLONGER LA FÊTE JUSQU'AU MATIN, NOTRE BAR EST L'ENDROIT PARFAIT POUR RENCONTRER DES AMIS ET PARTAGER DES MOMENTS MÉMORABLES.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Bar_Equipe;